import { createClient } from "contentful";

const useContentful = () => {
  const client = createClient({
    space: "8yq0z96jbrg8",
    accessToken: "FQKvYhD3y-m8981Hf1hqieNGxaK7Z6NV59PqfmznhNI",
    host: "preview.contentful.com",
  });

  const fetchRehvitoodContent = async () => {
    try {
      const response = await client.getEntries({
        content_type: "rehvitood",
      });

      if (response.items && response.items.length > 0) {
        return response.items;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching rehvitood content:", error);
      return null;
    }
  };
  const fetchRemonditooContent = async () => {
    try {
      const response = await client.getEntries({
        content_type: "remonditoo",
      });

      if (response.items && response.items.length > 0) {
        return response.items;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching remondi too:", error);
      return null;
    }
  };
  const fetchDynamicPages = async () => {
    try {
      const response = await client.getEntries({
        content_type: "pages",
      });
      if (response.items && response.items.length > 0) {
        return response.items;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching dynamic pages:", error);
      return null;
    }
  };

  return { fetchRehvitoodContent, fetchRemonditooContent, fetchDynamicPages };
};

export default useContentful;
