import React, { FC } from "react";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";

interface Page {
  pageTitle: string;
  pageRoute: string;
}

interface HeaderProps {
  scrollToTables?: () => void;
  scrollToContact?: () => void;
  pages: Page[];
}

const Header: FC<HeaderProps> = ({
  scrollToTables,
  scrollToContact,
  pages,
}): JSX.Element => {
  return (
    <div className="header">
      <div className="header__logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="header__links">
        <ul>
          <li onClick={scrollToTables}>Hinnakiri</li>
          <li onClick={scrollToContact}>Kontakt</li>
          {pages.map((page, index) => (
            <li key={index}>
              <Link
                to={`/${page.pageRoute}`}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                {page.pageTitle}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Header;
