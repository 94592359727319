import React, { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useContentful from "../../Contentful";

const Rehvitood: FC = (): JSX.Element => {
  const { fetchRehvitoodContent } = useContentful();
  const [table, setTable] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchRehvitoodContent();

        if (data) {
          const sortedData = data.sort((a, b) => {
            const aLength = Array.isArray(a.fields.hinnad)
              ? a.fields.hinnad.length
              : 0;
            const bLength = Array.isArray(b.fields.hinnad)
              ? b.fields.hinnad.length
              : 0;

            return aLength - bLength;
          });

          setTable(sortedData.reverse());
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="rehv">
      <div className="rehv__wrapper">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#0676ff" }}
          >
            <Typography variant="h6" color="#fff">
              Rehvitööde hinnakiri
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#0676ff" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Töö / materjali nimetus</TableCell>
                    <TableCell>13"-16"</TableCell>
                    <TableCell>17"-18"</TableCell>
                    <TableCell>19"-20"</TableCell>
                    <TableCell>21"-24"</TableCell>
                    <TableCell>Kaubik</TableCell>
                    <TableCell>Linnamaastur 16"-18"</TableCell>
                    <TableCell>Maastur 16"-18"</TableCell>
                    <TableCell>Maastur 19"-24"</TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <p>loading</p>
                ) : (
                  <TableBody>
                    {table.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.fields.materjal}</TableCell>
                        {Array.isArray(row.fields.hinnad) &&
                        row.fields.hinnad.length === 1 ? (
                          <>
                            <TableCell colSpan={8} align="center">
                              {row.fields.hinnad[0]}
                            </TableCell>
                          </>
                        ) : (
                          Array.isArray(row.fields.hinnad) &&
                          row.fields.hinnad.map((hind: any, index: number) => (
                            <TableCell key={index}>{hind}</TableCell>
                          ))
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Rehvitood;
