import React, { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useContentful from "../../Contentful";

const Remonditoo: FC = (): JSX.Element => {
  const { fetchRemonditooContent } = useContentful();
  const [table, setTable] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchRemonditooContent();

      if (data) {
        setTable(data.reverse());
      }
      setIsLoading(false);
    };
    setIsLoading(false);
    fetchData();
  }, []);

  return (
    <div className="rehv">
      <div className="rehv__wrapper">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#0676ff", textAlign: "center" }}
          >
            <Typography variant="h6" color="#fff">
              Remonditööde hinnakiri
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: "#0676ff" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Teenus</TableCell>
                    <TableCell>Hind</TableCell>
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <p>loading</p>
                ) : (
                  <TableBody>
                    {table.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          style={{ borderRight: "1px solid rgba(0,0,0,0.12)" }}
                        >
                          {row.fields.teenus}
                        </TableCell>
                        <TableCell>{row.fields.hind}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Remonditoo;
