
const DynamicHero = ({
  title,
  description,
}: {
  title: string;
  description: string;
}): JSX.Element => {
  return (
    <div className="hero">
      <div className="hero__wrapper">
        <div className="hero__title">
          <h1>{title}</h1>
          <h4>{description}</h4>
        </div>
      </div>
    </div>
  );
};

export default DynamicHero;
