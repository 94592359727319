import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function Contact() {
  return (
    <>
      <div className="wrapper">
        <h1>Kontakt</h1>
        <div className="line-break" />
      </div>
      <div className="contact">
        <div className="content-container">
          <div className="contact-wrapper">
            <div className="contact-grid">
              <div className="card">
                <div className="card-icon">
                  <HomeIcon />
                </div>
                <div className="card-address">
                  <h3>Aadress</h3>
                  <p>Maleva 2c, box 131, 10313 Tallinn</p>
                </div>
              </div>
              <div className="card">
                <div className="card-icon">
                  <StoreIcon />
                </div>
                <div className="card-address">
                  <h3>Lahtioleku ajad</h3>
                  <p>E-R: 09:00 - 18:00</p>
                </div>
              </div>
              <div className="card">
                <div className="card-icon">
                  <a
                    href="mailto:info@eduesta.ee"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <EmailIcon />
                  </a>
                </div>
                <div className="card-address">
                  <a
                    href="mailto:info@eduesta.ee"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <h3>Üldine e-post</h3>
                    <p>info@eduesta.ee</p>
                  </a>
                </div>
              </div>
              <div className="card">
                <div className="card-icon">
                  <a
                    href="tel:+3725112770"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <LocalPhoneIcon />
                  </a>
                </div>
                <div className="card-address">
                  <h3>Helistage meile</h3>
                  <a
                    href="tel:+3725112770"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <p>+372 5112770</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="map-wrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8110.387687861292!2d24.6910287!3d59.4564725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692939e91d4a753%3A0xfe44fbde5f9d43ff!2sEDUESTA%20O%C3%9C!5e0!3m2!1sen!2see!4v1679331619387!5m2!1sen!2see"
              className="map-style"
              style={{ border: 0 }}
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </>
  );
}
